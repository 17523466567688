import http from '../axios/http'

// 请求模块
const apiModel = 'message'

// 获取登录的图形验证码
export function getImageCode(params) {
  return http.postResponse(apiModel + '/mail/image/code', params, 'arraybuffer')
}

// 发送登录验证码邮件
export function sendMailCode(params) {
  return http.post(apiModel + '/mail/send/login/code', params)
}
