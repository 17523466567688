<template>
  <div class="login-mail">
    <el-form
      ref="loginForm"
      :rules="loginFormRules"
      :model="loginForm"
      label-width="100px"
      label-position="top"
      style="width: 80%"
    >
      <div v-if="loginStep === 1">
        <el-form-item label="邮箱账号" prop="mailAccount">
          <el-input
            :prefix-icon="Star"
            v-model.trim="loginForm.mailAccount"
            auto-complete="off"
            size="large"
            maxlength="100"
            clearable
            placeholder="请输入邮箱账号"
            @input="
              (mail) =>
                (loginForm.mailAccount =
                  mail.indexOf('@') > 0 ? mail.substring(0, mail.indexOf('@')) : mail)
            "
          >
            <template #append>
              <el-select
                placeholder="选择邮箱"
                v-model="loginForm.mailSuffix"
                style="background-color: #626aef; color: #eeeeee"
              >
                <el-option
                  v-for="(suffix, index) in mailSuffixOption"
                  :key="index"
                  :label="suffix"
                  :value="suffix"
                ></el-option>
              </el-select>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="imageCode">
          <el-input
            :prefix-icon="Lock"
            style="width: 60%"
            placeholder="请输入验证码"
            show-word-limit
            clearable
            size="large"
            v-model.trim="loginForm.imageCode"
            auto-complete="off"
            minlength="6"
            maxlength="6"
            :disabled="imageCode.disable"
            @input="inputImageCode"
            :readonly="imageCode.readonly"
          ></el-input>
          <el-button
            size="large"
            @click="loadImage"
            :disabled="imageCode.disable"
            type="primary"
            style="width: 40%; padding: 0; margin: 0; border: 0"
          >
            <el-image
              :src="imageCode.url"
              style="
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
              "
            >
              <template #error>
                {{ imageCode.text }}
              </template>
              <template #placeholder>
                {{ imageCode.text }}
              </template>
            </el-image>
          </el-button>
        </el-form-item>
      </div>
      <div v-if="loginStep === 2">
        <el-form-item label="邮箱账号">
          <el-input
            :prefix-icon="Star"
            v-model="loginForm.fullMailAccount"
            size="large"
            disabled
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码">
          <el-input
            :prefix-icon="Unlock"
            v-model="loginForm.mailCode"
            minlength="6"
            maxlength="6"
            @input="inputMailCode"
            show-word-limit
            clearable
            size="large"
          ></el-input>
        </el-form-item>
      </div>
      <div v-if="loginStep === 3">
        <el-result icon="success" title="登录成功" sub-title="正在跳转至个人中心..."></el-result>
      </div>
    </el-form>
  </div>
</template>

<script>
import { getImageCode, sendMailCode } from '@/api/MessageApi'
import { login } from '@/api/TenantApi'
import { Lock, Star, Unlock } from '@element-plus/icons-vue'
import { JSEncrypt } from 'jsencrypt'

export default {
  name: 'mailLogin',
  data() {
    return {
      loginForm: {
        mailAccount: '',
        mailSuffix: '',
        imageCode: '',
        fullMailAccount: '',
        mailCode: ''
      },
      loginFormRules: {
        mailAccount: [
          { required: true, message: '请输入登录的邮箱账号！', trigger: 'blur' },
          { min: 1, max: 100, message: '邮箱长度超出 1~100 范围！', trigger: 'blur' }
        ],
        imageCode: [
          { required: true, message: '请输入图形验证码！', trigger: 'blur' },
          { min: 6, max: 6, message: '请输入正确的图像验证码！', trigger: 'blur' }
        ]
      },
      imageCode: {
        url: '',
        text: '点击获取验证码',
        disable: false,
        readonly: true
      },
      loginStep: 1, // 1 获取和输入图形验证码  2 输入邮箱验证码  3 自动跳转个人主页
      mailSuffixOption: ['@163.com', '@qq.com', '@aliyun.com']
    }
  },
  setup() {
    return {
      Star,
      Lock,
      Unlock
    }
  },
  methods: {
    mailRsa(mail, key) {
      const encryptor = new JSEncrypt()
      encryptor.setPublicKey(key)
      return encryptor.encrypt(mail)
    },
    loadImage() {
      this.$refs.loginForm.validateField('mailAccount', () => {
        const mailAccount = this.loginForm.mailAccount
        const mailSuffix = this.loginForm.mailSuffix
        if (mailAccount && mailSuffix) {
          getImageCode({
            mail: this.mailRsa(mailAccount + mailSuffix, process.env.VUE_APP_BASE_MAIL_PUBLIC_KEY)
          }).then((res) => {
            try {
              const message = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(res)))
              if (message.code === 5003) {
                this.$message({ showClose: true, message: '邮箱账号格式错误！', type: 'error' })
              } else if (message.code === 5001) {
                this.imageCode.disable = true
                this.$message({
                  showClose: true,
                  message: '操作过于频繁！请稍候再试！',
                  type: 'error'
                })
              }
            } catch (e) {
              this.imageCode.text = ''
              this.imageCode.readonly = false
              this.imageCode.url =
                'data:image/png;base64,' +
                btoa(
                  new Uint8Array(res).reduce((data, byte) => data + String.fromCharCode(byte), '')
                )
            }
          })
        } else {
          this.$message({ showClose: true, message: '邮箱账号格式错误！', type: 'error' })
        }
      })
    },
    inputImageCode(code) {
      if (code.length === 6) {
        sendMailCode({
          imageCode: code,
          mailAccount: this.mailRsa(
            this.loginForm.mailAccount + this.loginForm.mailSuffix,
            process.env.VUE_APP_BASE_MAIL_PUBLIC_KEY
          )
        }).then((res) => {
          if (res.code === 200) {
            this.loginForm.fullMailAccount = this.loginForm.mailAccount + this.loginForm.mailSuffix
            this.$message({
              showClose: true,
              message: '验证码已发送至您的邮箱,请查收！',
              type: 'success'
            })
            this.loginStep = 2
          } else {
            this.$message({ showClose: true, message: res.msg, type: 'error' })
          }
        })
      }
    },
    inputMailCode(code) {
      if (code.length === 6) {
        login({
          account: this.mailRsa(
            this.loginForm.fullMailAccount + ':mail',
            process.env.VUE_APP_BASE_LOGIN_PUBLIC_KEY
          ),
          password: this.mailRsa(code, process.env.VUE_APP_BASE_LOGIN_PUBLIC_KEY)
        }).then((res) => {
          if (res.code === 200) {
            this.$store.dispatch('tenant/syncSetToken', res.data)
            this.loginStep = 3
            this.toUserHome()
          } else {
            this.$message({ showClose: true, message: res.msg, type: 'error' })
          }
        })
      }
    },
    toUserHome() {
      let time = 2
      const timer = setInterval(() => {
        time--
        if (time <= 0) {
          clearInterval(timer)
          const redirect = this.$route.query.redirect
          if (typeof redirect === 'undefined') {
            this.$router.push('/home/dashboard')
          } else {
            this.$router.replace(redirect)
          }
        }
      }, 1000)
    }
  },
  computed: {},
  watch: {},
  mounted() {}
}
</script>

<style scoped>
.login-mail {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.el-button >>> span {
  width: 100%;
  height: 100%;
}

.el-input >>> .el-input-group__append {
  width: 40%;
}
</style>
