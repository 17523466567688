<template>
  <div class="login">
    <div class="login-operation">
      <mail-login style="width: 100%; height: 100%"></mail-login>
    </div>
  </div>
</template>

<script>
import mailLogin from '@/views/login/components/mailLogin'

export default {
  name: 'Login',
  components: {
    'mail-login': mailLogin
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
.login {
  width: 100%;
  height: 100%;
  min-width: 700px;
  min-height: 600px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-image: url('../../assets/default/login_bg_01.jpg');
}

.login-operation {
  width: 450px;
  height: 278px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  box-shadow: 0 0 5px 0 #a5a3a3;
  transition: all 0.3s 0.1s linear;
}

/*.login-operation:hover {*/
/*  background: rgba(0, 0, 0, 0.6);*/
/*}*/
</style>
