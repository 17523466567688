import http from '../axios/http'

// 请求模块
const apiModel = 'tenant'

export function login(params) {
  return http.post(
    apiModel + '/login?account=' + params.account + '&password=' + params.password,
    null,
    { 'Content-Type': 'application/x-www-form-urlencoded' }
  )
}
